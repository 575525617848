import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ToastService } from './services';
import player from 'lottie-web';
import { provideCacheableAnimationLoader, provideLottieOptions } from 'ngx-lottie';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        AppRoutingModule,
        HttpClientModule,
        ToastrModule.forRoot({
            newestOnTop: true,
        }),
        BrowserAnimationsModule,
        ButtonModule
    ],
    providers: [
        ToastService,
        ConfirmationService,
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        },
        provideLottieOptions({
            player: () => player,
        }),
        provideCacheableAnimationLoader(),
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
